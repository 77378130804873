import Image from "next/image";
import Link from "next/link";

import { styled } from "stitches.config";

const LinkStyled = styled("a", {
  lineHeight: 0,
});

const ImageStyled = styled(Image, {
  lineHeight: 1,
  padding: 0,
});

export function Logo() {
  return (
    <Link href="/rent" passHref>
      <LinkStyled>
        <ImageStyled src="/svg/gleevery.svg" alt="Logo Gleevery" width={108} height={26} />
      </LinkStyled>
    </Link>
  );
}
