import { LogoViewOne } from "./footer/LogoViewOne";

export function Footer(props) {
  return (
    <footer className="position-relative" {...props}>
      <div className="container border-top py-5 px-4 px-lg-0">
        <div className="row d-flex align-items-center">
          <div className="col-24 col-md-auto mb-2 mb-md-0 rce">
            <div className="fs-xxs fw-normal text-gray d-flex flex-lg-row flex-column">
              &copy; 2022 - {new Date().getFullYear()} gleevery.com – Wszystkie prawa zastrzeżone{" "}
              <div className="d-flex flex-lg-row flex-column">
                <div className="d-flex">
                  <span className="mx-2 d-lg-block d-none">|</span>
                  <a
                    href="https://files.gleevery.com/Regulamin-wynajmu-dla-klienta-Gleevery.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="fs-xxs fw-normal text-gray text-decoration-none"
                  >
                    Regulamin
                  </a>
                  <span className="mx-2">|</span>
                  <a
                    href="https://gleevery.com/polityka-prywatnosci"
                    target="_blank"
                    rel="noreferrer"
                    className="fs-xxs fw-normal text-gray text-decoration-none"
                  >
                    Polityka prywatności
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-24 col-md-auto ms-auto rce">
            <p className="fs-xxs fw-normal text-gray d-flex align-items-center">
              Projekt i wykonanie: <LogoViewOne className={"ms-3"} />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
