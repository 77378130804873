import Image from "next/image";
import Link from "next/link";

import { styled } from "stitches.config";

const LinkStyled = styled("a", {
  lineHeight: 0,
});

const ImageStyled = styled(Image, {
  lineHeight: 1,
  padding: 0,
});

export function LogoViewOne(props) {
  return (
    <Link href="https://viewone.pl" passHref>
      <LinkStyled {...props} target="_blank">
        <ImageStyled src="/img/logo-viewone.svg" alt="Logo ViewOne" width={71} height={17} />
      </LinkStyled>
    </Link>
  );
}
